import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "header" ]

  connect() {
    const top_bar = this.headerTarget.querySelector("#header-brand-bar");
    this.content = document.getElementById("content");
    this.scroll_height = top_bar.offsetHeight;
    this.header_height = this.headerTarget.offsetHeight;
    window.addEventListener("scroll", (event) =>
      this.enable(event)
    )
  }

  enable(event) {
    const enabled = this.element.classList.contains("menu-on-top");

    if (!enabled && window.scrollY > this.scroll_height) {
      this.content.style.marginTop = this.header_height + "px";
      this.element.classList.add("menu-on-top");
    } else if (enabled && window.scrollY <= this.scroll_height) {
      this.content.style.marginTop = "0";
      this.element.classList.remove("menu-on-top");
    }
  }

}
