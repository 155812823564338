import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const element = this.element;
    this.element.addEventListener("show.bs.modal", (_event) => {
      const body = element.querySelector(".modal-body");
      const code = body.dataset.code;
      body.innerHTML = "<div class='ratio ratio-16x9'><iframe src='https://www.youtube-nocookie.com/embed/" + code + "' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>";
    });

    this.element.addEventListener("hide.bs.modal", (_event) => {
      const body = element.querySelector(".modal-body");
      body.innerHTML = '';
    });
  }

}
