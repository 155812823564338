import { Controller } from "@hotwired/stimulus"
// Shariff without jquery
// https://github.com/heiseonline/shariff/issues/372
// https://github.com/onli/shariff-plus
import "shariff-plus/dist/shariff.min.css";
const ShariffPlus = require('shariff-plus');

export default class extends Controller {

  connect() {
    new ShariffPlus(this.element, {
      // orientation: 'vertical'
    });
    const tooltips = this.element.querySelectorAll('.shariff-button > a');
    [...tooltips].map(
      element => {
	let tooltip = new bootstrap.Tooltip(element);
	element.addEventListener("click", element => tooltip.hide());
      }
    )
  }

}
