import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "results", "form" ]

  connect() {
    // nothing to do
  }

  blur(event) {
    // we have to set timeout since a click on a link will not work
    setTimeout(() => { this.resultsTarget.classList.add("d-none") }, 500);
  }

  keyDown(event) {
    switch (event.which) {
    case 40: // key: down
    case 38: // key: up
      break;
    default:
      return;
    }

    event.preventDefault();
    let items = this.resultsTarget.querySelectorAll("li.item");
    let focused = this.resultsTarget.querySelector("li.focus");
    let focused_index = null;
    items.forEach((item, index) => { if (item == focused) focused_index = index });

    switch (event.which) {
    case 40: // key: down
      if (focused) {
	if (items[focused_index + 1]) {
	  focused.classList.remove("focus");
	  items[focused_index + 1].classList.add("focus");
	}
      } else {
	items[0].classList.add("focus")
      }
      break;
    case 38: // key: up
      if (focused) {
	if (items[focused_index - 1]) {
	  focused.classList.remove("focus");
	  items[focused_index - 1].classList.add("focus");
	}
      }
      break;
    }
  }

  keyUp(event) {
    switch (event.which) {
    case 40: // key: down
    case 38: // key: up
      return;
    case 27: // key: esc
      this.blur();
      return
    case 13:
      let focused = this.resultsTarget.querySelector("li.focus");
      if (focused) {
	event.preventDefault();
	// navigate?
	document.location.href = focused.querySelector("a").getAttribute("href");
      }
      return
    }
    const form_data = new FormData(this.formTarget);
    const results_element = this.resultsTarget;

    if (this.inputTarget.value.length < 2) {
      results_element.classList.add("d-none");
      return;
    }

    fetch("/tasks/live_search", {
      method: "POST",
      body: form_data,
    }).then((response) => {
      if (response.ok) {
	return response.text();
      }
      return Promise.reject(response);
    }).then((body) => {
      results_element.innerHTML = body;
      results_element.classList.remove("d-none");
    }).catch((error) => {
      console.warn(error);
    });
  }

}
