import { Controller } from "@hotwired/stimulus"
import AudioPlayerApp from "../../elm_audio_player/elm-audio-player.min.js";

export default class extends Controller {

  connect() {
    const AudioPlayer = AudioPlayerApp.Elm.AudioPlayer;
    const element = this.element.querySelector(".elm-audio-player");

    AudioPlayer.init({
      node: element,
      flags: { tracks: JSON.parse(this.element.dataset.tracks),
	       title: this.element.dataset.title }
    });
  }

  disconnect() {
    // stop audio, otherwise it can keep running in background
    const audio = this.element.querySelector("audio");
    audio.src = "";
  }
}
